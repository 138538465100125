<template>
  <div id="home" style="background: #F0F0F0;">
    <SliderView></SliderView>
    <HeaderView></HeaderView>
    <TagsView></TagsView>
    <ContentView></ContentView>
  </div>
</template>

<script>
import { setTimeout, setInterval } from "timers";
const ContainerView = resolve =>
  require(["@/components/layout/index.vue"], resolve);
const SliderView = resolve =>
  require(["@/components/layout/slider/index.vue"], resolve);
const HeaderView = resolve =>
  require(["@/components/layout/header/index.vue"], resolve);
const TagsView = resolve =>
  require(["@/components/layout/tags/index.vue"], resolve);
const ContentView = resolve =>
  require(["@/components/layout/content/index.vue"], resolve);
export default {
  name: "home",
  data() {
    return {
      collapsed: false,
      tagsList: [],
      timeOut: {
        times: 0
      }
    };
  },
  methods: {
    //刷新token
    refreshToken() {
      this.$store.dispatch("user/refreshToken");
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.timeOut.times = setInterval(this.refreshToken, 1500000);
    this.$store
      .dispatch("StoreDataQuery/setQueryBySchool", {
        unitId: sessionStorage.getItem("unitId")
      })
      .then(res => {
        this.$store
          .dispatch("StoreDataQuery/setQueryByStarCost", {
            unitId: sessionStorage.getItem("unitId"),
            userId: sessionStorage.getItem("userId")
          })
          .then(res => {
            if (res.data) {
              let obj = {};
              res.data.map(item => {
                let ob = {};
                ob[item.starId] = item.starName;
                obj = { ...ob, ...obj };
              });
              this.$enums.starClass = { ...obj };
            }
          });
      });
  },
  destroyed() {
    clearInterval(this.timeOut.times);
  },
  components: {
    SliderView,
    HeaderView,
    TagsView,
    ContentView
  }
};
</script>
<style  lang="less">
@import "./../styles/public.less";
@import "./../styles/common.less";
@import "./../styles/my-theme.less";
</style>